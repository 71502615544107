import { Link, NavLink } from "react-router-dom";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function BottomHeader({ data }) {
  const [activeTab, setActiveTab] = useState("");
  const [isOpenMobile, setIsOpenMobile] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const [isNestedOpen, setIsNestedOpen] = useState(null);
  const [element, setElement] = useState([]);
  const mobileMenuRef = useRef(null);
  const [megaMenuOpen, setMegaMenuOpen] = useState("");

  const megaMenu = useMemo(
    () => data?.main_menu?.map((menuData) => menuData),
    [data]
  );
  const mainTabs = useMemo(() => megaMenu?.slice(0, 7), [megaMenu]);
  const moreTabs = useMemo(() => megaMenu?.slice(7), [megaMenu]);

  const handleDropdown = (tab, i) => {
    if (tab.menu_title === "Events") {
      setIsOpen(tab.menu_title);
    } else if (tab.menu_title === "Magazine") {
      setIsOpen(tab.menu_title);
    } else {
      setIsOpen(null);
    }
  };

  const openTab = (e, tab) => {
    setActiveTab(tab);
  };

  const handleMenu = (tab) => {
    setIsOpenMobile((prevOpenMenu) => (prevOpenMenu === tab ? null : tab));
  };

  const handleClickOutside = (event) => {
    if (
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target)
    ) {
      setIsOpenMobile(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (tab) => {
    if (tab?.child_category_data) {
      const defaultTab = tab?.child_category_data[0]?.category_name;
      const defaultPost = tab?.child_category_data[0];
      setActiveTab(defaultTab);
      setElement([tab.menu_title, defaultPost]);
    }
    // if (tab.child_page && tab.child_page.length > 0) {
    //   setDropdown([tab]);
    // }
    if (tab.menu_title === "Events" || tab.menu_title === "Magazine") {
      setIsOpen(true);
    }
  };

  const handleMegaMenu = (tab) => {
    // console.log(tab.menu_title);
    setMegaMenuOpen(tab.menu_title);
  };

  const getUrl = (tab) => {
    if (tab.menu_title === "Home") {
      return tab.menu_slug;
    } else if (tab.menu_type === "category") {
      return `/category/${tab.menu_slug}`;
    } else if (tab.menu_type === "page") {
      return "#";
    }
  };

  return (
    <>
      <div
        className={
          isOpenMobile
            ? "bottom_header mblur sm:pb-5 pb-3 lg:pt-3"
            : "bottom_header sm:pb-5 sm:py-3 pb-3"
        }
      >
        <nav className="container sm:px-7  mx-auto manupart">
          <div className="flex justify-between relative items-center bg-[#242424] sm:rounded-lg rounded-b-lg pr-2 sm:h-[60px] h-[50px]">
            <div className="overflow-y-hidden sm:mr-4 mr-2 sm:ml-0 ml-3 chrome-scroll">
              {/* Desktop NavBar */}
              <ul className="sm:flex text-white md:text-xs xl:text-sm text-xs sm:pb-0  pb-1 mt-1  sm:pl-0 pl-2  sm:mt-0 hidden ">
                {mainTabs?.map((tab, i) => (
                  <li
                    key={i}
                    onMouseEnter={() => {
                      handleMouseEnter(tab);
                      handleDropdown(tab, i);
                      handleMegaMenu(tab);
                    }}
                    onMouseLeave={() => {
                      setMegaMenuOpen("");
                    }}
                    className={
                      tab.menu_title !== "Home"
                        ? "hover:bg-black rounded-l-xl"
                        : ""
                    }
                  >
                    {tab.menu_title === "Home" ? (
                      <Link
                        to={getUrl(tab)}
                        className="flex justify-center items-center h-[60px] lg:px-2 cursor-pointer xl:px-3 px-2 whitespace-nowrap capitalize rounded-l-xl hover:bg-black"
                        onClick={() => setMegaMenuOpen("")}
                      >
                        {tab.menu_title}
                      </Link>
                    ) : tab.menu_type === "page" ? (
                      <span
                        // to={getUrl(tab)}
                        className="flex justify-center items-center h-[60px] lg:px-2 cursor-pointer xl:px-3 px-2 whitespace-nowrap capitalize rounded-l-xl hover:bg-black"
                        onClick={() => setMegaMenuOpen("")}
                      >
                        {tab.menu_title}
                      </span>
                    ) : (
                      <NavLink
                        to={getUrl(tab)}
                        className={({ isActive }) =>
                          isActive
                            ? "text-[#FF4242]  flex justify-center items-center h-[60px] lg:px-2 xl:px-3 px-2 whitespace-nowrap capitalize rounded-l-xl hover:bg-black"
                            : "flex justify-center items-center h-[60px] lg:px-2 xl:px-3 px-2 whitespace-nowrap capitalize rounded-l-xl hover:bg-black"
                        }
                        onClick={() => setMegaMenuOpen("")}
                      >
                        {tab.menu_title}
                      </NavLink>
                    )}
                    {tab["allow_child_post"] === "yes" && (
                      <div
                        className="mega_menu"
                        style={{
                          display:
                            megaMenuOpen === tab.menu_title ? "block" : "none",
                          opacity: megaMenuOpen === tab.menu_title ? "1" : "0",
                        }}
                      >
                        <div className="flex justify-start">
                          <div className="w-full p-8 bg-[#eeeeee] rounded-b-lg">
                            <section className="mx-auto">
                              <div key={i} className="tab">
                                {tab?.child_category_data?.map(
                                  (category, i) => (
                                    <Link
                                      key={i}
                                      to={`/category/${category.category_slug}`}
                                      className={`tablinks font-semibold ${
                                        activeTab ===
                                        `${category.category_name}`
                                          ? "active"
                                          : ""
                                      }`}
                                      onMouseOver={(e) => {
                                        openTab(e, `${category.category_name}`);
                                        setElement([tab.menu_title, category]);
                                      }}
                                      onClick={() => setMegaMenuOpen("")}
                                    >
                                      {category.category_name}
                                    </Link>
                                  )
                                )}
                              </div>
                              <div
                                id="link2"
                                className="tabcontent"
                                style={{ display: "block" }}
                              >
                                <div className="md:col-span-8 col-span-6 ">
                                  <Swiper
                                    key={element[1]?.category_name}
                                    className="swiper mySwiper"
                                    navigation={{
                                      prevEl: ".swiper-button-prev",
                                      nextEl: ".swiper-button-next",
                                    }}
                                    spaceBetween={20}
                                    slidesPerView={4}
                                    breakpoints={{
                                      520: { slidesPerView: 2 },
                                      768: { slidesPerView: 3 },
                                      1120: { slidesPerView: 4 },
                                    }}
                                  >
                                    {/* {console.log(element[1])} */}
                                    {element[1]?.child_posts?.map((post, i) => (
                                      <SwiperSlide key={i}>
                                        <div className="rounded-b-xl tabcontent-h">
                                          <div className="tabcontent1">
                                            <Link
                                              to={post.post_slug}
                                              onClick={() =>
                                                setMegaMenuOpen("")
                                              }
                                            >
                                              <img
                                                src={post.post_image}
                                                alt=""
                                                className="rounded-lg max-h-36"
                                              />
                                            </Link>
                                          </div>
                                          <div className="font-semibold text-sm block">
                                            <div className="p-5 text-black tabcontent2">
                                              <Link
                                                to={`/category/${post.cat.cate_slug}`}
                                                onClick={() =>
                                                  setMegaMenuOpen("")
                                                }
                                              >
                                                <h3 className="text-[#9E9E9E]  text-xs mb-2 os-sm-bold">
                                                  {post.cat.cate_title}
                                                </h3>
                                              </Link>
                                              <Link
                                                to={post.post_slug}
                                                onClick={() =>
                                                  setMegaMenuOpen("")
                                                }
                                              >
                                                <span className="block trans truncate-tex-nav font-semibold text-sm hover:text-red">
                                                  {post.post_title}
                                                </span>
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    ))}
                                    <React.Fragment key={i}>
                                      <div className="flex justify-end mt-6">
                                        <div className="swiper-button-prev bg-white rounded-lg"></div>
                                        <div className="swiper-button-next bg-white rounded-lg">
                                          Next
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </Swiper>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    )}
                    {tab["child_page"]?.length > 0 && (
                      <div className={isOpen ? "dropdown" : "hidden"}>
                        <ul className="absolute bg-[#eeeeee] text-black z-10">
                          {tab?.child_page?.map((child, index) => (
                            <li
                              key={index}
                              className="my-2 hover:bg-black hover:text-white"
                            >
                              <Link
                                to={child.menu_slug}
                                target={`${
                                  child.menu_target === "yes" ? "_blank" : ""
                                }`}
                                className="px-4 py-2 block "
                              >
                                {child.menu_title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
                {moreTabs?.length > 0 && (
                  <li
                    // onMouseEnter={() => setIsOpen(true)}
                    // onMouseLeave={() => setIsOpen(false)}
                    className=" hover:bg-black"
                  >
                    <span
                      className="flex justify-center items-center h-[60px] px-3 whitespace-nowrap capitalize hover:bg-black"
                      onMouseEnter={() => setIsOpen("More")}
                    >
                      More
                    </span>
                    <div
                      className={
                        isOpen ? "dropdown  bg-[#eeeeee] text-black" : "hidden"
                      }
                    >
                      <ul className="absolute bg-[#eeeeee] text-black z-10">
                        {moreTabs.map((tab, i) => (
                          <li
                            key={i}
                            onMouseEnter={() => setIsNestedOpen(tab.menu_title)}
                            onMouseLeave={() => setIsNestedOpen(null)}
                            className="my-2 hover:bg-black hover:text-white"
                          >
                            <Link to={getUrl(tab)} className="px-4 py-2 block">
                              {tab.menu_title}
                            </Link>
                            {tab["child_category_data"]?.length > 0 && (
                              <div
                                className={
                                  isNestedOpen === tab.menu_title
                                    ? "dropdown-2 bg-[#eeeeee] text-black"
                                    : "hidden"
                                }
                              >
                                <ul className="absolute right-full top-2 bg-[#eeeeee] text-black z-10">
                                  {tab["child_category_data"]?.map(
                                    (child, index) => (
                                      <li
                                        key={index}
                                        className="my-2 hover:bg-black hover:text-white"
                                      >
                                        <Link
                                          to={`category/${child.category_slug}`}
                                          className="px-4 py-2 text-nowrap block"
                                        >
                                          {child.category_name}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                )}
              </ul>

              {/* mobile NavBar */}
              <ul
                ref={mobileMenuRef}
                className="navbar  sm:hidden flex items-center py-3 overflow-auto"
              >
                {megaMenu?.map((tab, i) => (
                  <li key={i}>
                    {tab.menu_title === "Home" ? (
                      <Link
                        to={`/`}
                        className="text-[12px] text-white py-2 text-nowrap capitalize  px-2 inline-block font-bold"
                      >
                        {tab.menu_title}
                      </Link>
                    ) : (
                      <>
                        <span className="flex items-center justify-between  w-full px-2 ">
                          {tab.menu_type === "page" ? (
                            <span
                              // to={getUrl(tab)}
                              className="text-[12px] text-white py-2 text-nowrap  capitalize px-2 inline-block font-bold"
                            >
                              {tab.menu_title}
                            </span>
                          ) : (
                            <NavLink
                              to={getUrl(tab)}
                              className={({ isActive }) =>
                                isActive
                                  ? "text-red text-[12px] py-2 text-nowrap capitalize px-2 inline-block font-bold"
                                  : "text-[12px] text-white py-2 text-nowrap capitalize px-2 inline-block font-bold"
                              }
                            >
                              {tab.menu_title}
                            </NavLink>
                          )}

                          {tab.menu_title !== "Partner Content" && (
                            <svg
                              onClick={() => handleMenu(tab.menu_title)}
                              className="w-2.5 h-2.5 ms-3 text-white hover:cursor-pointer hover:text-red"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              {isOpenMobile === tab.menu_title ? (
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m1 5 4-4 4 4"
                                />
                              ) : (
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m1 1 4 4 4-4"
                                />
                              )}
                            </svg>
                          )}
                        </span>
                        {isOpenMobile === tab.menu_title &&
                        isOpenMobile !== "Partner Content" ? (
                          <div className={isOpenMobile ? "sub_menu" : "hidden"}>
                            <ul>
                              {tab["allow_child_post"] === "yes" && (
                                <>
                                  {tab?.child_category_data?.map(
                                    (category, i) => (
                                      <li key={category.category_name}>
                                        <Link
                                          to={`/category/${category.category_slug}`}
                                          className="leading-8"
                                          onClick={() => setIsOpenMobile(null)}
                                        >
                                          {category.category_name}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </>
                              )}
                              {tab["child_page"]?.length > 0 && (
                                <>
                                  {tab?.child_page?.map((el, index) => (
                                    <li key={el.menu_slug}>
                                      <Link
                                        to={`${el.menu_slug}`}
                                        className="leading-8"
                                        onClick={() => setIsOpenMobile(null)}
                                      >
                                        {el.menu_title}
                                      </Link>
                                    </li>
                                  ))}
                                </>
                              )}
                              {tab["allow_child_post"] === "no" && (
                                <>
                                  {tab?.child_category_data?.map(
                                    (category, i) => (
                                      <li key={i}>
                                        <Link
                                          key={i}
                                          to={`/category/${category.category_slug}`}
                                          className="leading-8"
                                          onClick={() => setIsOpenMobile(null)}
                                        >
                                          {category.category_name}
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </>
                              )}
                            </ul>
                          </div>
                        ) : null}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="md:flex items-center lg:space-x-5 space-x-1  hidden">
              {data?.other_websites?.map((item, index) => (
                <a
                  key={index}
                  href={item.other_website_link}
                  target={item.link_target === "Yes" ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                >
                  <img
                    src={item.other_webiste_logo}
                    alt={`Logo ${index}`}
                    className={`w-full h-full"
                     ${
                       index === 2 ? " lg:block hidden " : "max-md:block hidden"
                     }
                    `}
                  />
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
export default BottomHeader;
