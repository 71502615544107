import { useEffect, useState, lazy } from "react";
import { Constants } from "../../Middleware/Constant";
import ImageModal from "./ImageAdModal";
import TopHeader from "./TopHeader";
import MidHeader from "./MidHeader";
import BottomHeader from "./BottomHeader";

function Header() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError("");
        const response = await fetch(Constants.backendUrl + "header", {
          headers: {
            Authorization: `Basic ${Constants.token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Something went wrong");
        }

        const jsonData = await response.json();
        if (data.code === 401) throw new Error("Data not found");
        setData(jsonData.data);
        setError("");
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>{error.message}</div>;
  // }

  return (
    <header>
      <div className="">
        <TopHeader data={data} />
        <div className="bg-[#231E1E]  sm:bg-transparent sm:mt-[10px] md:mt-0">
          <MidHeader data={data} />
        </div>
      </div>
      <BottomHeader data={data} />
      {/* <ImageModal /> */}
    </header>
  );
}

export default Header;
