import { useEffect, useRef, useState } from "react";
import close_modal from "../../assets/img/close-modal.svg";
import { Constants } from "../../Middleware/Constant";

const SignUpModal = ({ modalOpen2, handleClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const signUpModalRef = useRef();

  const validateForm = () => {
    if (!firstName || !lastName) {
      setError("All fields are required.");
      return false;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Invalid email address.");
      return false;
    }
    if (!role) {
      setError("Please select your role.");
      return false;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return false;
    }
    return true;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setError("");
    try {
      const reqData = new URLSearchParams();
      reqData.append("email_addess", email);
      reqData.append("password", password);
      reqData.append("first_name", firstName);
      reqData.append("last_name", lastName);
      reqData.append("user_role", role);

      const payload = {
        method: "POST",
        headers: {
          Authorization: `Basic ${Constants.token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: reqData.toString(),
      };

      const response = await fetch(
        Constants.backendUrl + "user-register",
        payload
      );
      if (response.ok) {
        const data = await response.json();
        if (data?.code === 401) {
          throw new Error(data?.message);
        }
        setSuccess(data.message);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setRole("");

        // handleClose();
      } else {
        throw new Error("Failed to register");
      }
    } catch (error) {
      setError("Failed to sign up. Please try again later.");
    }
  };

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess("");
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success, error]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        signUpModalRef.current &&
        !signUpModalRef.current.contains(event.target)
      ) {
        handleClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [signUpModalRef, handleClose]);

  return (
    <div className="login_form_section">
      <div
        className={`modal_popup opacity-${modalOpen2 ? "100" : "0"}`}
        style={{ display: modalOpen2 ? "flex" : "none" }}
      >
        <div className="mx-auto p-14 mt-2 overflow-y-auto min-w-[60vw]">
          <div className="bg-white rounded p-5" ref={signUpModalRef}>
            <div className="text-end sm:block hidden">
              <button
                className="px-4 py-2"
                onClick={() => handleClose()}
                // ref={modalCloseButtonRef}
              >
                <img src={close_modal} alt="Close" />
              </button>
            </div>
            <div className="flex justify-between sm:hidden">
              {" "}
              <h2 className="text-[20px] text-black text-center font-black mb-5">
                Sign Up
              </h2>{" "}
              <button
                className="px-4 h-2"
                onClick={() => handleClose()}
                // ref={modalCloseButtonRef}
              >
                <img src={close_modal} className="h-5" alt="Close" />
              </button>
            </div>
            <h2 className="text-[20px] sm:block hidden text-black text-center font-black mb-5">
              Sign Up
            </h2>
            <div className="login_form">
              <form onSubmit={handleSignUp}>
                <div className="sm:flex sm:justify-between sm:space-x-2">
                  <div className="mb-2 sm:w-1/2 w-full">
                    <input
                      type="text"
                      placeholder="First Name"
                      className=" py-2 px-4 bg-white rounded-10 w-full border border-gray focus:outline-none"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="mb-2 sm:w-1/2 w-full">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className=" py-2 px-4 bg-white rounded-10 w-full border border-gray focus:outline-none"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:flex sm:justify-between sm:space-x-2">
                  {" "}
                  <div className="mb-2 sm:w-2/3">
                    <input
                      type="email"
                      placeholder="Email Address"
                      className=" py-2 px-4 bg-white rounded-10 w-full border border-gray focus:outline-none"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-2 sm:w-1/3">
                    <select
                      id="user_role1"
                      className="shadow-sm w-full px-4 py-2 bg-white border border-gray-300 focus:outline-none"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      // onChange={handleChange}
                      aria-label="Select Who you are"
                      required
                    >
                      <option value="">I Am a</option>
                      <option value="Retailer">Retailer</option>
                      <option value="Wholesaler">Wholesaler</option>
                      <option value="Client">Client</option>
                    </select>
                  </div>
                </div>
                <div className="mb-2 relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className=" py-2 px-4 bg-white rounded-10 w-full border border-gray focus:outline-none"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#bbb"
                      stroke="#bbb"
                      className="w-[20px] h-[20px] absolute right-4 cursor-pointer"
                      viewBox="0 0 128 128"
                    >
                      <path
                        d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                        data-original="#000000"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="mb-2">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className=" py-2 px-4 bg-white rounded-10 w-full border border-gray focus:outline-none"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {success && (
                  <p className="text-green-600 text-sm mb-2">{success}</p>
                )}
                {error && <p className="text-red text-sm mb-2">{error}</p>}
                <button
                  onClick={handleSignUp}
                  type="button"
                  className="text-14 mt-5 px-20 lg:py-3 py-2 bg-red block text-white rounded-[10px] text-xs text-center mx-auto"
                >
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
