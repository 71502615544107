import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Search({ onClose }) {
  const [inputValue, setInputValue] = useState("");
  const searchContainerRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;
    onClose();
    setInputValue("");
    navigate(`/s/?s=${encodeURIComponent(inputValue)}`);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchContainerRef, onClose]);

  return (
    <>
      <div className="fixed  inset-0 z-50 flex items-start justify-center pt-16 sm:pt-24">
        <div className="fixed inset-0 bg-slate-900/25 backdrop-blur transition-opacity opacity-100"></div>
        <div
          ref={searchContainerRef}
          className="relative w-full max-w-lg transform px-4 transition-all opacity-100 scale-100"
        >
          <div className="overflow-hidden rounded-lg bg-white shadow-md">
            <form onSubmit={handleSubmit}>
              <div className="relative flex">
                <input
                  className="block w-full appearance-none bg-transparent py-4 pl-4 pr-12 text-base text-slate-900 placeholder:text-slate-600 focus:outline-none relative sm:text-sm sm:leading-6"
                  placeholder="Find anything..."
                  aria-label="Search components"
                  type="search"
                  name="search"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                ></input>
                <button
                  type="submit"
                  className="m-2 mr-5  text-black"
                  aria-label="Search"
                >
                  <svg
                    className="fill-current h-3 w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    style={{ enableBackground: "new 0 0 56.966 56.966" }}
                    xmlSpace="preserve"
                    width="512px"
                    height="512px"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
