import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function TopHeader({ data }) {
  const [inputValue, setInputValue] = useState("");
  const [openMore, setOpenMore] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  const [visibleItems, setVisibleItems] = useState([]);
  const [hiddenItems, setHiddenItems] = useState([]);
  // const menuRef = useRef(null);

  // const menuElement = menuRef.current;
  // if (menuElement) {
  //   const totalWidth = menuElement.scrollWidth;
  //
  // }

  const searchParams = new URLSearchParams(search);
  const query = searchParams.get("s");

  useEffect(() => {
    if (query) {
      setInputValue(query);
    }
    return () => {
      setInputValue("");
    };
  }, [query, pathname]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;
    setInputValue("");
    navigate(`/s/?s=${encodeURIComponent(inputValue)}`);
  };

  const handleHover = () => {
    setOpenMore((open) => !open);
  };

  useEffect(() => {
    const updateMenuItems = () => {
      const screenWidth = window.innerWidth;
      let numOfVisibleItems = 5;

      if (screenWidth < 1200) numOfVisibleItems = 5;
      if (screenWidth < 1100) numOfVisibleItems = 5;
      if (screenWidth < 1040) numOfVisibleItems = 5;
      if (screenWidth < 980) numOfVisibleItems = 5;

      setVisibleItems(data?.top_menu?.slice(0, numOfVisibleItems) || []);
      setHiddenItems(data?.top_menu?.slice(numOfVisibleItems) || []);
    };

    updateMenuItems();
    window.addEventListener("resize", updateMenuItems);

    return () => window.removeEventListener("resize", updateMenuItems);
  }, [data]);

  return (
    <div className="top_header">
      {/* <p>Current URL: {location.pathname}</p> */}
      <div className="top-header mb-[10px] md:block hidden">
        <div className="container px-7 mx-auto">
          <div className="flex justify-between items-center lg:px-10 px-5 bg-[#E8E8E8] h-[46px] rounded-br-lg rounded-bl-lg">
            <div className=" mr-4 pb-2 mt-2 ">
              <ul className="flex  lg:space-x-4 space-x-2 font-semibold whitespace-nowrap">
                {visibleItems?.map((menu, index) => (
                  <React.Fragment key={index}>
                    {menu.menu_target === "Yes" ? (
                      <li>
                        <a
                          href={menu.menu_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {menu.menu_title}
                        </a>
                      </li>
                    ) : (
                      <li>
                        <NavLink
                          to={`/${menu.menu_slug}`}
                          className={({ isActive }) =>
                            isActive ? "text-red mlink" : "mlink"
                          }
                        >
                          {menu.menu_title}
                        </NavLink>
                      </li>
                    )}
                  </React.Fragment>
                ))}
                {hiddenItems.length > 0 && (
                  <li
                    className="relative"
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                  >
                    <div className="inline-block">
                      <button className="mlink text-xs">More</button>
                      <ul
                        className={`${
                          openMore
                            ? "absolute left-0  bg-black text-white px-2 shadow-lg z-10  group-hover:block"
                            : "hidden"
                        }`}
                      >
                        {hiddenItems?.map((menu, index) => (
                          <li key={index}>
                            {menu.menu_target === "Yes" ? (
                              <a
                                href={menu.menu_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-xs"
                              >
                                {menu.menu_title}
                              </a>
                            ) : (
                              <Link
                                to={`/${menu.menu_slug}`}
                                className="text-xs"
                              >
                                {menu.menu_title}
                              </Link>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <div className="lg:w-[522px] ">
              <form method="post" onSubmit={handleSearch}>
                <div className="flex w-full my-3 rounded-[40px] border border-[#231E1E]">
                  <input
                    className=" w-full border-none bg-transparent px-4 py-1 text-[10px] outline-none focus:outline-none  mr-10 hdr-srch"
                    type="search"
                    name="search"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Search..."
                    autoComplete="off"
                  />
                  <button
                    type="submit"
                    className="m-2 mr-5 text-black"
                    aria-label="Search"
                  >
                    <svg
                      className="fill-current h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 56.966 56.966"
                      style={{ enableBackground: "new 0 0 56.966 56.966" }}
                      xmlSpace="preserve"
                      width="512px"
                      height="512px"
                    >
                      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
