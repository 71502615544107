import { useContext, useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import close_modal from "../../assets/img/close-modal.svg";
import { Constants } from "../../Middleware/Constant";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../Context/SidebarContext";
// import { data } from "autoprefixer";

function LoginModal({ modalOpen, handleClose }) {
  const [usernameInput, setUsernameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const loginModalRef = useRef(null);
  const { setUser } = useContext(DataContext);

  const clearError = () => {
    setEmailError("");
    setPasswordError("");
  };

  const userLogin = async () => {
    setLoading(true);
    clearError("");
    try {
      const credential = new URLSearchParams();
      credential.append("email_addess", usernameInput);
      credential.append("password", passwordInput);

      const payload = {
        method: "POST",
        headers: {
          Authorization: `Basic ${Constants.token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: credential.toString(),
      };

      const response = await fetch(
        Constants.backendUrl + "user-login",
        payload
      );

      if (response.ok) {
        const data = await response.json();
        if (data?.code === 401) {
          throw new Error(data?.message);
        }
        if (data?.data.length === 0) {
          throw new Error(data?.message);
        }
        localStorage.setItem("ATuserData", JSON.stringify(data?.data?.user));
        setUser(data?.data?.user);
        setUsernameInput("");
        setPasswordInput("");
        // window.location.reload();
        window.open(data?.data?.login_url, "_blank");
        handleClose();
      } else {
        throw new Error("Failed to login");
      }
    } catch (error) {
      setEmailError(error.message);
      setTimeout(clearError, 3000);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validateForm()) {
      userLogin();
    }
  };

  const validateForm = () => {
    let valid = true;

    if (!usernameInput) {
      setEmailError("UserName is required.");
      valid = false;
    } else if (!validateUsername(usernameInput)) {
      setEmailError("Please enter a valid Username");
      valid = false;
    } else {
      setEmailError("");
    }

    if (!passwordInput) {
      setPasswordError("Password is required.");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!valid) {
      setTimeout(clearError, 3000);
    }

    return valid;
  };

  // const validateEmail = (email) => {
  //   console.log(email);
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };
  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9._@-]{3,30}$/;
    return usernameRegex.test(username);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalOpen &&
        loginModalRef.current &&
        !loginModalRef.current.contains(event.target)
      ) {
        handleClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [loginModalRef, handleClose]);

  return (
    <div className="login_form_section">
      <div
        className={`modal_popup ${modalOpen ? "opacity-100" : "opacity-0"}`}
        style={{ display: modalOpen ? "flex" : "none" }}
      >
        <div className="mx-auto sm:p-14 p-2 sm:mt-2 my-auto overflow-y-auto md:!min-w-[750px]  min-w-[80vw] ">
          <div ref={loginModalRef} className="bg-white rounded p-5 ">
            <div className="mb-2 sm:block hidden text-end">
              <button className="px-4 py-3" onClick={handleClose}>
                <img src={close_modal} alt="Close" />
              </button>
            </div>
            <div className="flex justify-between sm:hidden">
              {" "}
              <h2 className="text-[20px] text-black text-center font-black mb-5">
                Login
              </h2>
              <button className="px-4 h-2" onClick={handleClose}>
                <img src={close_modal} className="h-5" alt="Close" />
              </button>
            </div>
            <h2 className="text-[20px] text-black text-center sm:block hidden font-black mb-5">
              Login
            </h2>
            <div className="login_form">
              <form onSubmit={handleLogin}>
                <div className="mb-2">
                  <input
                    type="text"
                    placeholder="Username"
                    className="sm:py-3 py-2 px-4 sm:text-sm md:text-lg text-xs bg-white rounded-10 w-full border border-gray focus:outline-none"
                    value={usernameInput}
                    onChange={(e) => setUsernameInput(e.target.value)}
                  />
                  {emailError && (
                    <p className="text-red text-sm">{emailError}</p>
                  )}
                </div>
                <div className="mb-2">
                  <input
                    type="password"
                    placeholder="Password"
                    className="sm:py-3 py-2 px-4 sm:text-sm md:text-lg text-xs bg-white rounded-10 w-full border border-gray focus:outline-none"
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                  />
                  {passwordError && (
                    <p className="text-red text-sm">{passwordError}</p>
                  )}
                </div>
                <button
                  type="submit"
                  className={`text-14 mt-5 sm:px-20 px-10 lg:py-3 py-2 bg-red block text-white rounded-[10px] text-xs text-center mx-auto ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={loading} // Disable button during loading
                >
                  {loading ? "Logging In..." : "Log In"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
